import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { IconEmail } from './Icon/IconEmail';
import { t } from '../domain/services/configService';
import { theme } from '../domain/theme/theme';

export const InputGroupButton = ({
  onFocus,
  onClick,
  type,
  name,
  placeholder,
  label,
  size,
  id,
  icon,
  forwardedRef
}) => {
  // At this moment, this InputGroupButton is used only by email
  const IconComponent = icon === 'email' ? IconEmail : null;

  return (
    <label
      htmlFor={id}
      css={css`
        height: 45px;
        padding: 0;
        width: 250px;
        display: block;
        margin-bottom: 75px;
        ${theme.mq.tablet} {
          margin-bottom: 27px;
        }
      `}
    >
      <input
        onFocus={onFocus}
        ref={forwardedRef}
        type={type}
        name={name}
        id={id}
        size={size}
        aria-required="true"
        aria-invalid="false"
        placeholder={t(placeholder)}
        css={css`
          height: 100%;
          padding: 8px 8px 8px 14px;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: 0.5px;
          border: none;
          display: inline-block;
          color: #000000;
          float: left;
          max-width: calc(100% - 45px);
          font-weight: normal;
          border-radius: 0;
          ${theme.font.family('regularWeb')};
        `}
      />
      <button
        type="submit"
        onClick={onClick}
        aria-label={t(label)}
        css={css`
          width: 45px;
          padding: 10px;
          height: 100%;
          display: inline-block;
          -webkit-appearance: none;
          border: none;
          background-color: #fc0;
        `}
      >
        <IconComponent size={24} color="black" />
      </button>
    </label>
  );
};

InputGroupButton.defaultProps = {
  size: 40,
  placeholder: undefined,
  name: undefined,
  type: 'text',
  onClick: undefined,
  onFocus: undefined,
  icon: 'arrow-right',
  label: undefined,
  forwardedRef: undefined
};

InputGroupButton.propTypes = {
  size: PropTypes.number,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string,
  forwardedRef: PropTypes.objectOf(PropTypes.any)
};
