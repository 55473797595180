import React from 'react';
import { WithIcon } from './WithIcon';

export const IconCheck = WithIcon(
  'Gallery',
  '0 0 40 40',
  <path
    id="prefix__a"
    d="M17.97 28.323c-.325 0-.634-.14-.847-.385l-6.354-6.984 1.708-1.539 5.37 5.923 9.538-13.507 1.892 1.323L18.923 27.83c-.198.293-.523.475-.877.492h-.077zM20 39.446c-7.935.006-15.092-4.769-18.133-12.098C-1.174 20.018.5 11.579 6.109 5.966 11.718.353 20.156-1.328 27.488 1.707 34.819 4.742 39.6 11.896 39.6 19.831c-.008 10.824-8.776 19.598-19.6 19.615zm0-36.923C12.998 2.517 6.683 6.73 3.999 13.197 1.315 19.664 2.792 27.11 7.74 32.064c4.949 4.953 12.394 6.436 18.863 3.758 6.47-2.678 10.688-8.99 10.688-15.991C37.275 10.285 29.546 2.548 20 2.523z"
  />
);
