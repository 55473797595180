import React from 'react';
import { WithIcon } from './WithIcon';

export const IconEmail = WithIcon(
  'Email',
  '0 0 1024 1024',
  <path
    transform="scale(1, -1) translate(0, -960)"
    d="M10 782v-668h1004v668h-1004zM964 164h-904v568h904v-568zM920.8 639.2l-23.8 44-387-208-386.6 208.4-23.8-44 410.4-221.2z"
  />
);
